import { useTranslations } from 'next-intl'

import { DesktopHeader } from '~modules/desktop/components'
import { DesktopFooter } from '~modules/desktop/components'

import { Error } from '../components/Error'

export const ErrorPage = () => {
  const t = useTranslations('error')

  return (
    <>
      <DesktopHeader />
      <Error
        title={t('title')}
        message={t.rich('message', {
          br: () => <br />,
          strong: (child) => <strong>{child}</strong>,
        })}
        btnLabel={t('btnLabel')}
        whatsappInstruction={t('whatsapp.instruction')}
        whatsappBtnLabel={t('whatsapp.btnLabel')}
        whatsappNumber={t('whatsapp.number')}
        whatsappMessage={t('whatsapp.message')}
        backPath="/"
      />
      <DesktopFooter />
    </>
  )
}
