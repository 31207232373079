import { createGlobalStyle, keyframes } from 'styled-components'

const shineBefore = keyframes`
  0% {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
  } 
  
  50% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 0.6;
  }

  100% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 0.6;
  }
`

const shineAfter = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100px) skewX(-15deg);
  } 
  
  50% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 1;
  }
`

export const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-tap-highlight-color: transparent;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /*
  * Global theming styles
  */
  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${(props) => props.theme.typography.fontFamilies.content};
    color: ${(props) => props.theme.colors.black};
    -webkit-font-smoothing: antialiased;
  }

  h1 {
    font-family: ${(props) => props.theme.typography.h1.fontFamily};
    font-size: ${(props) => props.theme.typography.h1.fontSize};
    font-weight: ${(props) => props.theme.typography.h1.fontWeight};
    letter-spacing: ${(props) => props.theme.typography.h1.letterSpacing};
    line-height: ${(props) => props.theme.typography.h1.lineHeight};
  }

  h2 {
    font-family: ${(props) => props.theme.typography.h2.fontFamily};
    font-size: ${(props) => props.theme.typography.h2.fontSize};
    font-weight: ${(props) => props.theme.typography.h2.fontWeight};
    letter-spacing: ${(props) => props.theme.typography.h2.letterSpacing};
    line-height: ${(props) => props.theme.typography.h2.lineHeight};
  }

  h3 {
    font-family: ${(props) => props.theme.typography.h3.fontFamily};
    font-size: ${(props) => props.theme.typography.h3.fontSize};
    font-weight: ${(props) => props.theme.typography.h3.fontWeight};
    letter-spacing: ${(props) => props.theme.typography.h3.letterSpacing};
    line-height: ${(props) => props.theme.typography.h3.lineHeight};
  }

  h4 {
    font-family: ${(props) => props.theme.typography.h4.fontFamily};
    font-size: ${(props) => props.theme.typography.h4.fontSize};
    font-weight: ${(props) => props.theme.typography.h4.fontWeight};
    letter-spacing: ${(props) => props.theme.typography.h4.letterSpacing};
    line-height: ${(props) => props.theme.typography.h4.lineHeight};
  }

  .CheckboxContainer {
    align-items: flex-start;
    .CheckboxCheckContainer {
      margin-top: 0.25rem;
    }
  }

  .Label {
    font-size: 16px
  }
  
  .RadioText {
    font-size: 14px
  }

  .SCCountryLabel {
    font-size: 1.125rem;
  }

  .rounded-modal {
    border-radius: 1rem;
  }

  .shiny {
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      width: 60px;
      height: 100%;
      left: 0;
      top: 0;
      filter: blur(30px);
      animation: ${shineBefore} 2.25s ease-in-out infinite;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.2);
      width: 30px;
      height: 100%;
      left: 30px;
      top: 0;
      opacity: 0;
      animation: ${shineAfter} 2.25s ease-in-out infinite;
    }
  }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.RadioText > strong {
  font-family: 'Inter Bold';
}
Button.Outline.Primary {
  background-color: white;
}
`
