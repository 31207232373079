import { Button } from '@mymoons/ui-library'
import { FC } from 'react'

import MoonsLogo from '~assets/icons/moons-isologotype.svg'
import { useEvaluationQuery } from '~hooks/useEvaluationQuery'
import { getUtmsQuery } from '~utilities/utms.utilities'

import * as Styled from './Header.styled'

interface HeaderProps {
  showButton?: boolean
}

export const Header: FC<HeaderProps> = ({ showButton = false }) => {
  const { data: evaluation } = useEvaluationQuery()

  const scheduleAppointmentUrl = evaluation
    ? process.env.NEXT_PUBLIC_APPOINTMENT_URL.concat(`/${evaluation?.publicKey}`, getUtmsQuery())
    : process.env.NEXT_PUBLIC_QUIZ_URL.concat(getUtmsQuery())

  return (
    <Styled.Container withButton={showButton}>
      <Styled.LogoLink
        href={process.env.NEXT_PUBLIC_WEBSITE_URL!.concat(getUtmsQuery())}
        target="_blank"
        rel="noopener noreferrer"
      >
        <MoonsLogo />
      </Styled.LogoLink>
      {showButton && (
        <Button
          label="Agendar cita"
          size="small"
          variant="outline"
          href={scheduleAppointmentUrl}
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
    </Styled.Container>
  )
}
