import { DesktopHeader, DesktopContent, DesktopFooter } from '../components'

export const DesktopPage = () => {
  return (
    <>
      <DesktopHeader />
      <DesktopContent />
      <DesktopFooter />
    </>
  )
}
