import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'

interface GoogleTagManagerScriptProps {
  id: string
}

const pageview = (url: string) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  })
}

export const GoogleTagManagerScript: FC<GoogleTagManagerScriptProps> = ({ id }) => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])

  return (
    <Script
      id="gtag-base"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${id}');
          `,
      }}
    />
  )
}

export const NoScriptGoogleTagManager: FC<GoogleTagManagerScriptProps> = ({ id }) => {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  )
}
