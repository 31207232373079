import { ASSETS_S3 } from '~constants/assetsS3.constants'

export const getS3Image = (name: string) => {
  return {
    src: `${ASSETS_S3}/images/${name}@1x.webp`,
    srcSet: `${ASSETS_S3}/images/${name}@1x.webp 1x,
    ${ASSETS_S3}/images/${name}@2x.webp 2x,
    ${ASSETS_S3}/images/${name}@3x.webp 3x`,
  }
}
