import MoonsLogo from '~assets/icons/moons-isologotype.svg'
import { getUtmsQuery } from '~utilities/utms.utilities'

import * as Styled from './DesktopHeader.styled'

export const DesktopHeader = () => {
  return (
    <Styled.Header>
      <Styled.Container>
        <Styled.LogoLink
          href={process.env.NEXT_PUBLIC_WEBSITE_URL!.concat(getUtmsQuery())}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MoonsLogo />
        </Styled.LogoLink>
      </Styled.Container>
    </Styled.Header>
  )
}
