import { appUtms } from '~constants/utms.constant'

type BaseUtms = typeof appUtms

interface Utms extends BaseUtms {
  [key: string]: string
}

type GetUtmsQuery = (utms?: Utms) => string

export const getUtmsQuery: GetUtmsQuery = (utms = appUtms) => {
  return '?'.concat(new URLSearchParams(utms).toString())
}
