import styled from 'styled-components'

export const Container = styled.header<{ withButton: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.withButton ? 'space-between' : 'center')};
  padding: 1.25rem 1rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
`

export const LogoLink = styled.a`
  display: block;
`
