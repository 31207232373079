import styled from 'styled-components'

export const MainContainer = styled.div`
  overflow-y: scroll;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 7.5rem);
  background: linear-gradient(180deg, #ffd7ba -88.69%, #ffdfc8 -88.69%, #ffffff 55.21%);
  @media (min-width: 700px) {
    justify-content: flex-start;
  }
`

export const ContainerBody = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: 700px) {
    flex-direction: row;
    padding-top: 3vh;
  }
`
export const ContainerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 700px) {
    flex: 5;
    align-items: flex-start;
  }
`
export const AstronautImg = styled.img`
  object-fit: contain;
  flex: 6;
  @media (min-width: 700px) {
    height: 29.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

export const Title = styled.h3`
  margin-top: 2.5rem;
  @media (min-width: 700px) {
    font-size: 3.5rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
`
export const Message = styled.p`
  font-family: ${(props) => props.theme.typography.body1.fontFamily};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  font-style: ${(props) => props.theme.typography.body1.fontStyle};
  font-weight: ${(props) => props.theme.typography.body1.fontWeight};
  letter-spacing: ${(props) => props.theme.typography.body1.letterSpacing};
  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  color: ${(props) => props.theme.typography.body1.color};
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
  strong {
    font-weight: bold;
  }
  @media (min-width: 700px) {
    width: 25rem;
    padding-left: 0;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const WhatsappInstruction = styled.p`
  font-family: ${(props) => props.theme.typography.body1.fontFamily};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  font-style: ${(props) => props.theme.typography.body1.fontStyle};
  font-weight: ${(props) => props.theme.typography.body1.fontWeight};
  letter-spacing: 0.005em;
  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  color: ${(props) => props.theme.colors.grayscale[600]};
  padding-left: 15%;
  padding-right: 15%;
  @media (min-width: 700px) {
    width: 25rem;
    padding-left: 0;
    text-align: left;
  }
`
export const ButtonContainerWatsapp = styled.div`
  margin-top: 1.5rem;
  @media (min-width: 700px) {
    a {
      padding-left: 0 !important;
    }
  }
`
