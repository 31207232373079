import styled from 'styled-components'

interface ShowProps {
  on: 'desktop' | 'mobile'
}

export const Show = styled.div<ShowProps>`
  display: ${(props) => (props.on.includes('mobile') ? 'block' : 'none')};
  @media (min-width: 480px) {
    display: ${(props) => (props.on.includes('desktop') ? 'block' : 'none')};
  }
`
