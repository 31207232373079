import { useTranslations } from 'next-intl'

import MoonsLogo from '~assets/icons/moons-isologotype.svg'
import WhatsappIcon from '~assets/icons/whatsapp.svg'

import * as Styled from './Footer.styled'

export const Footer = () => {
  const t = useTranslations('common')

  return (
    <Styled.Container>
      <MoonsLogo width={64} />
      <Styled.Divider />
      <Styled.SectionTitle>{t('footer.supportText')}</Styled.SectionTitle>
      <Styled.Section>
        <Styled.SectionLink
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.NEXT_PUBLIC_WHATSAPP}
        >
          {t('footer.chatText')}
          <WhatsappIcon />
        </Styled.SectionLink>
        <Styled.SectionLink
          target="_blank"
          rel="noopener noreferrer"
          href={t('footer.phoneAction')}
        >
          {t('footer.phoneText')}
        </Styled.SectionLink>
        <Styled.SectionLink
          target="_blank"
          rel="noopener noreferrer"
          href={t('footer.emailAction')}
        >
          {t('footer.emailText')}
        </Styled.SectionLink>
      </Styled.Section>
      <Styled.Divider />
      <Styled.Section spacing="1rem">
        <div>
          <Styled.SectionText>{t('footer.copyright')}</Styled.SectionText>
          <Styled.SectionText>{t('footer.rights')}</Styled.SectionText>
        </div>
        <Styled.SectionLink
          target="_blank"
          rel="noopener noreferrer"
          href={t('legal.privacyAction')}
        >
          {t('legal.privacyText')}
        </Styled.SectionLink>
      </Styled.Section>
      <Styled.Divider />
      <Styled.SectionText align="center">{t('footer.permission')}</Styled.SectionText>
    </Styled.Container>
  )
}
