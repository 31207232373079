import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem 1.0625rem;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`

export const Title = styled.h2`
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  text-align: center;
  line-height: 1.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const Description = styled.p`
  margin-top: 1rem;
  text-align: center;
  line-height: 1.5rem;
`

export const Footer = styled.footer`
  margin-top: 1.5rem;
  display: grid;
  gap: 1rem;
  .FullWidth {
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
  }
  .ButtonText {
    font-size: 0.875rem;
  }
`
