import { Button } from '@mymoons/ui-library'
import { FC, useEffect } from 'react'

import { useApp } from '~hooks/useApp'

import * as Styled from './Error.styled'

interface ErrorProps {
  title: string
  message: any
  btnLabel: string
  whatsappInstruction: string
  whatsappBtnLabel: string
  whatsappMessage: string
  whatsappNumber: string
  backPath: string
}

export const Error: FC<ErrorProps> = ({
  title,
  message,
  btnLabel,
  whatsappInstruction,
  whatsappBtnLabel,
  whatsappMessage,
  whatsappNumber,
  backPath,
}) => {
  const { setIsOnErrorPage } = useApp()

  useEffect(() => {
    setIsOnErrorPage(true)
    return () => {
      setIsOnErrorPage(false)
    }
  }, [setIsOnErrorPage])

  return (
    <Styled.MainContainer>
      <Styled.ContainerBody>
        <Styled.AstronautImg
          src="https://prod-moons-assets.s3.amazonaws.com/error-astronaut.svg"
          alt="Astronaut"
          width={150}
          height={150}
        />
        <Styled.ContainerContent>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Message>{message}</Styled.Message>
          <Styled.ButtonContainer>
            <Button color="red" label={btnLabel} size="medium" href={backPath} />
          </Styled.ButtonContainer>
          <Styled.WhatsappInstruction>{whatsappInstruction}</Styled.WhatsappInstruction>
          <Styled.ButtonContainerWatsapp>
            <Button
              color="dark"
              label={whatsappBtnLabel}
              size="medium"
              href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${whatsappMessage}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="textProtected"
            />
          </Styled.ButtonContainerWatsapp>
        </Styled.ContainerContent>
      </Styled.ContainerBody>
    </Styled.MainContainer>
  )
}
