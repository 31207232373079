import { Component, ErrorInfo, ReactNode } from 'react'

import { ErrorPage } from '~modules/error/pages/Error.page'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    if (!this.state.hasError) return this.props.children

    return <ErrorPage />
  }
}
