import { useRouter } from 'next/router'
import { createContext, useCallback, useMemo, useState } from 'react'
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react'

import type { ModalContentProps } from '~components/ModalContent/ModalContent'
import { Evaluation } from '~models/evaluation.model'
import VIEWS from '~modules/pictures/models/ActiveViews.model'

export interface AppContextValues {
  modal: ModalContentProps | undefined
  setModal: Dispatch<SetStateAction<ModalContentProps | undefined>>
  isOnErrorPage: boolean
  setIsOnErrorPage: Dispatch<SetStateAction<boolean>>
  openUnknownErrorModal: () => void
  openProblemUploadingImageModal: () => void
  openProblemLinkingImageModal: () => void
  openProblemTakePhotoModal: () => void
  redirectToLastCompletedStep: (evaluation: Evaluation) => void
}

export const AppContext = createContext({} as AppContextValues)

export interface AppContextProviderProps {
  children: ReactNode
}

export const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<ModalContentProps | undefined>()
  const [isOnErrorPage, setIsOnErrorPage] = useState<boolean>(false)
  const router = useRouter()

  // TODO: Move translations
  const openUnknownErrorModal = useCallback(() => {
    setModal({
      title: 'Ocurrió un error inesperado',
      description:
        'Encontramos un problema durante tu proceso. Contacta a nuestro equipo de soporte para darle seguimiento.',
      primaryButtonLabel: 'Aceptar',
      onPrimaryButtonClick: () => setModal(undefined),
    })
  }, [])

  const openProblemUploadingImageModal = useCallback(() => {
    setModal({
      title: 'Ocurrió un error al subir tu fotografía',
      description:
        'Encontramos un problema al subir tu fotografía. Inténtalo más tarde o contacta a nuestro equipo de soporte.',
      primaryButtonLabel: 'Aceptar',
      onPrimaryButtonClick: () => setModal(undefined),
    })
  }, [])

  const openProblemLinkingImageModal = useCallback(() => {
    setModal({
      title: 'Ocurrió un error al enviar tu fotografía',
      description:
        'Encontramos un problema al enviar tu fotografía. Inténtalo más tarde o contacta a nuestro equipo de soporte.',
      primaryButtonLabel: 'Aceptar',
      onPrimaryButtonClick: () => setModal(undefined),
    })
  }, [])

  const redirectToLastCompletedStep = useCallback((evaluation: Evaluation) => {
    if (evaluation?.pictures?.lower?.url) return router.replace('/informacion')
    if (evaluation?.pictures?.superior?.url)
      return router.replace(`/fotos/inferior/${VIEWS.instructions}`)
    if (evaluation?.pictures?.frontal?.url)
      return router.replace(`/fotos/superior/${VIEWS.instructions}`)
    return router.replace(`/fotos/frontal/${VIEWS.instructions}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openProblemTakePhotoModal = useCallback(() => {
    setModal({
      title: 'Ocurrió un error al tomar la fotografía',
      description:
        'Encontramos un problema al tomar tu fotografía. Inténtalo de nuevo o contacta a nuestro equipo de soporte.',
      primaryButtonLabel: 'Aceptar',
      onPrimaryButtonClick: () => setModal(undefined),
    })
  }, [])

  const value = useMemo(() => {
    return {
      modal,
      setModal,
      isOnErrorPage,
      setIsOnErrorPage,
      openUnknownErrorModal,
      openProblemUploadingImageModal,
      openProblemLinkingImageModal,
      openProblemTakePhotoModal,
      redirectToLastCompletedStep,
    }
  }, [
    modal,
    isOnErrorPage,
    openUnknownErrorModal,
    openProblemUploadingImageModal,
    openProblemLinkingImageModal,
    openProblemTakePhotoModal,
    redirectToLastCompletedStep,
  ])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
