import { useRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import { useQuery } from 'react-query'

import { EMAIL_STORAGE_KEY } from '~constants/storage.constants'
import { getEvaluation } from '~services/evaluations.service'

export const useEvaluationQuery = () => {
  const cookies = parseCookies()
  const router = useRouter()
  const publicKey = router.query.publicKey as string | undefined
  const email = cookies.email

  const evaluationKey = email || publicKey

  return useQuery(['evaluations', evaluationKey], () => getEvaluation({ email: evaluationKey! }), {
    enabled: !!evaluationKey,
    onSuccess: (evaluation) => {
      setCookie(null, EMAIL_STORAGE_KEY, evaluation.email, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
    },
  })
}
