/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from 'react'
import type { FC } from 'react'

import useEventListener from '~hooks/useEventListener'

type OptionProps = {
  id: string
  flag: string
  value: string
  name: string
}

type Props = {
  onChange?: (e: any) => void
  options: Array<OptionProps>
  value: any
  noSuggestionsLbl?: string
  placeholder?: string
}

/**
 * Select country dropdown component
 * @param {*} param0 props
 * @param {Array} param0.options list of options for dropdown
 * @param {Object} param0.value dropdown value option object
 * @param {Function} param0.onChange handle change for dropdown value
 * @param {String} param0.noSuggestionsLbl label for no suggestions in autocomplete dropdown
 * @returns {Void} component
 */
export const SelectCountry: FC<Props> = ({
  options,
  value,
  onChange = () => {},
  noSuggestionsLbl = 'No suggestions available',
  placeholder = 'Buscar por país',
}) => {
  const [selectedOption, setSelectedOption] = useState<
    | {
        id: string
        flag: string
        value: string
        name: string
      }
    | any
  >(null)
  const [activeSuggestion, setActiveSugg] = useState(0)
  const [filteredSuggestions, setFilteredSugg] = useState([...options])
  const [showSuggestions, setShowSugg] = useState(false)
  const [userInput, setUserInput] = useState('')

  const toggling = useCallback(() => {
    setShowSugg(!showSuggestions)
  }, [showSuggestions])

  const closeDropdown = useCallback(() => {
    if (showSuggestions) {
      setShowSugg(false)
      setUserInput('')
      setFilteredSugg([...options])
    }
  }, [options, showSuggestions])

  const onChangeAutoComp = useCallback(
    (e: any) => {
      const userInput = e.currentTarget.value
      const filteredSuggestions = options.filter(
        (suggestion: any) => suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
      )

      setActiveSugg(0)
      setFilteredSugg(filteredSuggestions)
      setUserInput(e.currentTarget.value)
      setShowSugg(true)
    },
    [options],
  )

  const onSelectAutoComp = useCallback(
    (option: any) => {
      setActiveSugg(0)
      setFilteredSugg([...options])
      setUserInput('')
      setSelectedOption(option)
      onChange(option)
      setShowSugg(false)
    },
    [onChange, options],
  )

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        setActiveSugg(0)
        setUserInput('')
        setSelectedOption(filteredSuggestions[activeSuggestion])
        onChange(filteredSuggestions[activeSuggestion])
        setShowSugg(false)
      } else if (e.keyCode === 38) {
        if (activeSuggestion === 0) {
          return
        }
        setActiveSugg(activeSuggestion - 1)
      } else if (e.keyCode === 40) {
        if (activeSuggestion + 1 === filteredSuggestions.length) {
          return
        }
        setActiveSugg(activeSuggestion + 1)
      }
    },
    [activeSuggestion, filteredSuggestions, onChange],
  )

  useEffect(() => {
    const found = options.find((item) => item.id === value?.id)
    setSelectedOption(found || options[0])
    onChange(found || null)
  }, [onChange, options, value])

  useEventListener('click', closeDropdown, null)

  const expandClass = ['material-icons', 'DDExpandArrow']

  if (showSuggestions) {
    expandClass.push('DDExpandArrowActive')
  }

  return (
    <div className="SCMainContainer">
      <button type="button" className="SCHeaderContainer" onClick={toggling}>
        <span role="img" aria-label="country-selection" className="SCCountryLabel">
          {selectedOption?.flag || ''}
        </span>
        <span className={expandClass.join(' ')}>expand_more</span>
      </button>
      {showSuggestions && (
        <div className="SCListContainer">
          <ul className="DDList">
            <li style={{ listStyle: 'none' }}>
              <input
                className="DDMenuInput"
                type="text"
                onChange={onChangeAutoComp}
                onKeyDown={onKeyDown}
                value={userInput}
                onClick={(e) => e.stopPropagation()}
                placeholder={placeholder}
              />
            </li>
            <div style={{ overflowY: 'scroll', maxHeight: '280px' }}>
              {filteredSuggestions.length ? (
                filteredSuggestions.map((option, index) => (
                  <li
                    className={[
                      'DDListItem',
                      index === activeSuggestion ? 'DDItemActive' : '',
                    ].join(' ')}
                    key={option.id}
                  >
                    <button type="button" onClick={() => onSelectAutoComp(option)}>
                      <span>
                        <span role="img" aria-label={option.id}>
                          {option.flag}
                        </span>{' '}
                        {option.name} <span className="SCCountryName">{option.value}</span>
                      </span>
                      {option.id === selectedOption.id && (
                        <span
                          className={['material-icons', 'DDItemSelected'].join(' ')}
                          style={{ fontSize: '16px' }}
                        >
                          done
                        </span>
                      )}
                    </button>
                  </li>
                ))
              ) : (
                <li className="DDItemNoSugg">{noSuggestionsLbl}</li>
              )}
            </div>
          </ul>
        </div>
      )}
    </div>
  )
}
