import { DefaultTheme } from 'styled-components'

export const colors: DefaultTheme['colors'] = {
  black: '#000000',
  white: '#FFFFFF',
  emphasys: {
    [50]: '#FDE9ED',
    [100]: '#F8BCCA',
    [200]: '##F38FA6',
    [300]: '#F07894',
    [400]: '#E9355F',
    [500]: '#E61E4D',
    [600]: '#CF1B45',
    [700]: '#B8183E',
    [800]: '#8A122E',
    [900]: '#730F27',
  },
  specialty: {
    blue: {
      [50]: '#FAFAFA',
      [100]: '#ECEEFB',
      [200]: '#D2D7F0',
      [300]: '#949CE3',
      [400]: '#535FCF',
      [500]: '#0E1FC1',
      [600]: '#0A1899',
      [700]: '#081270',
      [800]: '#050C4D',
      [900]: '#212121',
    },
    red: {
      [50]: '#FAFAFB',
      [100]: '#ECEEFB',
      [200]: '#D2D7F0',
      [300]: '#949CE3',
      [400]: '#535FCF',
      [500]: '#0E1FC1',
      [600]: '#0A1899',
      [700]: '#081270',
      [800]: '#050C4D',
      [900]: '#212121',
    },
    purple: {
      [50]: '#FAFAFA',
      [100]: '#ECEEFB',
      [200]: '#D2D7F0',
      [300]: '#949CE3',
      [400]: '#535FCF',
      [500]: '#0E1FC1',
      [600]: '#0A1899',
      [700]: '#081270',
      [800]: '#050C4D',
      [900]: '#212121',
    },
    green: {
      [50]: '#FAFAFA',
      [100]: '#ECEEFB',
      [200]: '#D2D7F0',
      [300]: '#949CE3',
      [400]: '#535FCF',
      [500]: '#0E1FC1',
      [600]: '#0A1899',
      [700]: '#081270',
      [800]: '#050C4D',
      [900]: '#212121',
    },
    yellow: {
      [50]: '#FAFAFB',
      [100]: '#ECEEFB',
      [200]: '#D2D7F0',
      [300]: '#949CE3',
      [400]: '#535FCF',
      [500]: '#0E1FC1',
      [600]: '#0A1899',
      [700]: '#081270',
      [800]: '#050C4D',
      [900]: '#212121',
    },
    orange: {
      [50]: '#FAFAFA',
      [100]: '#ECEEFB',
      [200]: '#D2D7F0',
      [300]: '#949CE3',
      [400]: '#535FCF',
      [500]: '#0E1FC1',
      [600]: '#0A1899',
      [700]: '#081270',
      [800]: '#050C4D',
      [900]: '#212121',
    },
  },
  grayscale: {
    [50]: '#FAFAFA',
    [100]: '#F5F5F5',
    [200]: '#EEEEEE',
    [300]: '#E0E0E0',
    [400]: '#BDBDBD',
    [500]: '#9E9E9E',
    [600]: '#757575',
    [700]: '#616161',
    [800]: '#424242',
    [900]: '#212121',
  },
}
