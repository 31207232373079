import { Button } from '@mymoons/ui-library'
import { FC, ReactElement } from 'react'

import * as Styled from './ModalContent.styled'

export interface ModalContentProps {
  Icon?: () => ReactElement
  title: string
  description: string
  primaryButtonLabel: string
  onPrimaryButtonClick: () => void
  secondaryButtonLabel?: string
  onSecondaryButtonClick?: () => void
}

export const ModalContent: FC<ModalContentProps> = ({
  Icon,
  title,
  description,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
}) => {
  return (
    <Styled.Container>
      {Icon && (
        <Styled.IconContainer>
          <Icon />
        </Styled.IconContainer>
      )}
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
      <Styled.Footer>
        <Button
          isButton
          color="red"
          label={primaryButtonLabel}
          size="medium"
          variant="filled"
          fullWidth
          onClick={onPrimaryButtonClick}
        />
        {secondaryButtonLabel && onSecondaryButtonClick && (
          <Button
            isButton
            color="dark"
            label={secondaryButtonLabel}
            size="medium"
            variant="outline"
            fullWidth
            onClick={onSecondaryButtonClick}
          />
        )}
      </Styled.Footer>
    </Styled.Container>
  )
}
