import type { Country } from '~components/Input'
import type { SendWhatsappMessageParams } from '~services/evaluations.service'

import type { PhoneSchema } from '../models/phone'

interface Data extends PhoneSchema {
  countryCode: Country
  tokenId: string
}

export const adaptFormValuesToService = (data: Data): SendWhatsappMessageParams => {
  return {
    countryCode: data.countryCode.id,
    dialCode: data.countryCode.value,
    Phone: data.phone,
    tokenId: data.tokenId,
  }
}
