import { Locales } from '~models/locales.model'

interface PhoneNumberValidations {
  min: number
  max: number
}

type PhoneNumberLengths = {
  [locale in Locales]: PhoneNumberValidations
} & {
  default: PhoneNumberValidations
  MX: PhoneNumberValidations
}

const phoneNumberLengths: PhoneNumberLengths = {
  'es-MX': {
    min: 10,
    max: 10,
  },
  MX: {
    min: 10,
    max: 10,
  },
  default: {
    min: 6,
    max: 15,
  },
}

export const getPhoneNumberLength = (locale: Locales) => {
  return phoneNumberLengths[locale] || phoneNumberLengths.default
}

export const localeToCountryCode = (locale: Locales) => {
  return locale.slice(-2)
}
