import styled from 'styled-components'

export const RadialGradient = styled.div<{
  width?: number
  height?: number
  top?: number
  left?: number
  bottom?: number
  right?: number
  zIndex?: number
  color: string
}>`
  width: ${(props) => (props.width ? props.width : 360)}px;
  height: ${(props) => (props.height ? props.height : 360)}px;
  left: ${(props) => (props.left ? `${props.left}px` : '')};
  top: ${(props) => (props.top ? `${props.top}px` : '')};
  bottom: ${(props) => (props.bottom ? `${props.bottom}px` : '')};
  right: ${(props) => (props.right ? `${props.right}px` : '')};
  background: radial-gradient(circle, ${(props) => props.color} 0%, rgba(0, 0, 0, 0) 70%);
  border-radius: 50%;
  position: absolute;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1)};
`
