import { useRouter } from 'next/router'
import { FC, ReactNode, useEffect } from 'react'
import Modal from 'react-responsive-modal'

import { ModalContent } from '~components/ModalContent'
import { useApp } from '~hooks/useApp'
import { useMediaQuery } from '~hooks/useMediaQuery'

import { Footer } from './components/Footer'
import { Header } from './components/Header'

interface LayoutProps {
  children: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const router = useRouter()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const { modal, setModal, isOnErrorPage } = useApp()
  const showAppointmentButtonInHeader = router.asPath.includes('resultados')

  const closeModal = () => setModal(undefined)

  // Closes the modal whenever the route changes, for not doing it imperartively
  useEffect(() => {
    closeModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])

  return (
    <>
      {isMobile && !router.asPath.includes('camara') && !isOnErrorPage && (
        <Header showButton={showAppointmentButtonInHeader} />
      )}
      <main>{children}</main>
      <Modal
        open={!!modal}
        closeOnEsc={!router.pathname.startsWith('/fotos')}
        closeOnOverlayClick={!router.pathname.startsWith('/fotos')}
        onClose={closeModal}
        center
        showCloseIcon={false}
        classNames={{ modal: 'rounded-modal' }}
      >
        {!!modal && <ModalContent {...modal} />}
      </Modal>
      {isMobile && !router.asPath.includes('camara') && !isOnErrorPage && <Footer />}
    </>
  )
}
