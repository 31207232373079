/**
 * Swagger Docs
 * https://dev-moons-api-docs.s3.amazonaws.com/api-os-prediagnostics/index.html
 */

import axios from 'redaxios'

import type { Evaluation } from '~models/evaluation.model'

const client = axios.create({ baseURL: process.env.NEXT_PUBLIC_MOONS_OS_API_URL })

export type CreateEvaluationParams = Pick<
  Evaluation,
  'name' | 'email' | 'country' | 'dialCode' | 'phone'
> &
  Partial<Pick<Evaluation, 'treatmentInfo' | 'lastVisit' >>

export type CreateEvaluationResponse = Pick<
  Evaluation,
  | 'country'
  | 'createdAt'
  | 'customerId'
  | 'dealId'
  | 'dialCode'
  | 'email'
  | 'name'
  | 'objectId'
  | 'phone'
  | 'publicKey'
  | 'state'
  | 'updatedAt'
  | 'useMasterKey'
>

export const createEvaluation = async (evaluation: CreateEvaluationParams) => {
  const { data } = await client.post<CreateEvaluationResponse>('/evaluations', evaluation)
  return data
}

export type UpdateEvaluationParams = Partial<Evaluation>

export const updateEvaluation = async ({ email, ...evaluation }: UpdateEvaluationParams) => {
  const { data } = await client.put<Evaluation>(`/evaluations/email/${email}`, {
    ...evaluation,
  })
  return data
}

export type GetEvaluationParams = Pick<Evaluation, 'email'>

export const getEvaluation = async (evaluation: GetEvaluationParams) => {
  const { data } = await client.get<Evaluation>(`/evaluation/${evaluation.email}`)
  return data
}

export interface SendWhatsappMessageParams {
  Phone: string
  dialCode: string
  countryCode: string
  tokenId: string
}

export const sendWhatsappMessage = async (payload: SendWhatsappMessageParams) => {
  const { data } = await client.post(`/send-notification`, payload)
  return data
}
