import styled from 'styled-components'

export const Header = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: 72px;
  top: 0;
  position: sticky;
  z-index: 3;
`

export const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  height: inherit;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
  display: flex;
  align-items: center;
`

export const LogoLink = styled.a`
  display: block;
  width: 9rem;
  svg {
    width: 100%;
    height: auto;
  }
`
