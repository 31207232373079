import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, Button } from '@mymoons/ui-library'
import { encode as base64Encode } from 'base-64'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import type { KeyboardEvent } from 'react'
import { useForm, Controller } from 'react-hook-form'

import SuccessIcon from '~assets/icons/success.svg'
import { Input } from '~components/Input'
import type { Country } from '~components/Input'
import { RadialGradient } from '~components/RadialGradient'
import countryCodes from '~constants/countryCodes.constant.json'
import { useApp } from '~hooks/useApp'
import { useSendWhatsappLinkMutation } from '~hooks/useSendWhatsappLinkMutation'
import type { Locales } from '~models/locales.model'
import { adaptFormValuesToService } from '~modules/desktop/adapters/whatsapp.adapter'
import { getPhoneSchema } from '~modules/desktop/models/phone'
import type { PhoneSchema } from '~modules/desktop/models/phone'
import { getS3Image } from '~utilities/getS3Image.utilities'
import { getPhoneNumberLength } from '~utilities/locales.utilities'
import { localeToCountryCode } from '~utilities/locales.utilities'

import * as Styled from './DesktopContent.styled'

export const DesktopContent = () => {
  const router = useRouter()
  const [countryCode, setCountryCode] = useState<Country>()
  const [phoneMaxLength, setPhoneMaxLength] = useState<number>(15)
  const [phoneMinLength, setPhoneMinLength] = useState<number>(6)
  const t = useTranslations('common')
  const { setModal } = useApp()
  const { mutate: sendWhatsappLink, isLoading: isSendingLink } = useSendWhatsappLinkMutation()

  useEffect(() => {
    setCountryCode(countryCodes.find((c) => c.id === localeToCountryCode(router.locale as Locales)))
  }, [router.locale])

  useEffect(() => {
    if (countryCode) {
      const locale = countryCode.id as Locales
      const { max, min } = getPhoneNumberLength(locale)
      setPhoneMaxLength(max)
      setPhoneMinLength(min)
    }
  }, [countryCode])

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm<PhoneSchema>({
    mode: 'all',
    resolver: yupResolver(getPhoneSchema(t, countryCode?.id || router.locale)),
    defaultValues: {
      phone: '',
      confirmPhone: false,
    },
  })

  const onFormSubmit = async (data: PhoneSchema) => {
    const tokenIdString = JSON.stringify({
      phone: data.phone,
      countryCode: countryCode?.id,
    })
    const tokenIdEncoded = base64Encode(tokenIdString)

    sendWhatsappLink(
      adaptFormValuesToService({
        ...data,
        countryCode: countryCode!,
        tokenId: tokenIdEncoded,
      }),
      {
        onSuccess: () => {
          setModal({
            Icon: SuccessIcon,
            title: '¡Gracias!',
            description: 'Hemos enviado el enlace a tu celular',
            primaryButtonLabel: 'Ir a inicio',
            onPrimaryButtonClick: () => router.reload(),
          })
        },
        onError: () => {
          setModal({
            title: 'Algo salió mal',
            description: 'Ocurrió un error al enviar el link a tu celular',
            primaryButtonLabel: 'Intentar de nuevo',
            onPrimaryButtonClick: () => setModal(undefined),
          })
        },
      },
    )
  }

  return (
    <Styled.Content>
      <Styled.Container>
        <Styled.ImageContainer>
          <Styled.Image
            {...getS3Image('only-mobile')}
            alt={t('desktop.imageAlt')}
            width={724}
            height={697}
          />
        </Styled.ImageContainer>
        <Styled.FormContainer>
          <Styled.Form>
            <Styled.Title>{t('desktop.title')}</Styled.Title>
            <Styled.Instruction>
              {t.rich('desktop.instruction', {
                strong: (child) => <strong>{child}</strong>,
              })}
            </Styled.Instruction>
            <Styled.InputLable>{t('desktop.input.label')}</Styled.InputLable>
            <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Styled.InputContainer>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Input
                      id={name}
                      name={name}
                      fullWidth
                      type="text"
                      isCountryCode
                      error={errors?.phone?.message}
                      label=""
                      placeholder=""
                      variant="outline"
                      value={value}
                      onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
                        if (event.charCode !== 13 && !/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={onChange}
                      valueCountryCode={countryCode}
                      onChangeCode={(country) => setCountryCode(country)}
                      maxLength={phoneMaxLength}
                      prefixList={countryCodes}
                      pattern="[0-9]*"
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.CheckboxContainer>
                <Controller
                  name="confirmPhone"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Checkbox
                      disabled={watch('phone').length >= phoneMinLength ? false : true}
                      id={name}
                      name={name}
                      checked={value}
                      error={errors?.confirmPhone?.message}
                      onChange={onChange}
                      label={t.rich('desktop.confirmPhone', {
                        phoneNumber: `${countryCode?.value}${watch('phone')}`,
                        strong: (child) => <strong>{child}</strong>,
                      })}
                    />
                  )}
                />
              </Styled.CheckboxContainer>
              <Styled.ButtonContainer>
                <Button
                  isButton
                  type="submit"
                  color="red"
                  label={t('desktop.send')}
                  size="medium"
                  variant="filled"
                  fullWidth
                  disabled={!isValid}
                  loading={isSendingLink}
                />
              </Styled.ButtonContainer>
            </form>
          </Styled.Form>
        </Styled.FormContainer>
      </Styled.Container>
      <RadialGradient width={600} height={600} bottom={-200} right={-144} color={'#b9c1f480'} />
    </Styled.Content>
  )
}
