import type { DefaultTheme } from 'styled-components'

import { colors } from './colors'
const display = 'Work Sans, sans-serif'
const content = 'Inter, sans-serif'

export const typography: DefaultTheme['typography'] = {
  fontFamilies: {
    display,
    content,
  },
  h1: {
    fontFamily: display,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: '1.75rem',
    letterSpacing: '-0.005em',
    color: colors.grayscale[900],
  },
  h2: {
    fontFamily: display,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '2.5rem',
    lineHeight: '3.75rem',
    letterSpacing: '0',
    color: colors.grayscale[900],
  },
  h3: {
    fontFamily: display,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '3rem',
    letterSpacing: '0.0025em',
    color: colors.grayscale[900],
  },
  h4: {
    fontFamily: display,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
    letterSpacing: '0',
    color: colors.grayscale[900],
  },
  subtitle1: {
    fontFamily: display,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    letterSpacing: '0.0015em',
    color: colors.grayscale[900],
  },
  subtitle2: {
    fontFamily: display,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.0015em',
    color: colors.grayscale[900],
  },
  body1: {
    fontFamily: content,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.0015em',
    color: colors.grayscale[900],
  },
  body2: {
    fontFamily: content,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.0025em',
    color: colors.grayscale[900],
  },
  overline: {
    fontFamily: content,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.015em',
    color: colors.grayscale[900],
  },
}
