import { useTranslations } from 'next-intl'

import * as Styled from './DesktopFooter.styled'

export const DesktopFooter = () => {
  const t = useTranslations('common')

  return (
    <Styled.Footer>
      <Styled.SectionTextDesktop align="center">
        {t('footer.copyright')}
        {t('footer.rights')}
      </Styled.SectionTextDesktop>
    </Styled.Footer>
  )
}
