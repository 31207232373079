/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from 'react'
import type { FC, SyntheticEvent, MouseEventHandler } from 'react'

import { SelectCountry } from './components/SelectCountry'

type InputThemesProps = {
  variant: any
  icons: any
}

export const InputThemes: InputThemesProps = {
  variant: {
    default: 'OutlineInput',
    inline: 'Inline',
  },
  icons: {
    regular: 'material-icons',
    outlined: 'material-icons-outlined',
    sharp: 'material-icons-sharp',
    round: 'material-icons-round',
  },
}

export interface Country {
  id: string
  value: string
  flag: string
  name: string
}

export const countryList = [
  {
    id: 'initial',
    value: '',
    flag: '🏳️',
    name: 'Sin selección',
  },
  {
    id: 'mx',
    value: '+52',
    flag: '🇲🇽',
    name: 'México',
  },
  {
    id: 'co',
    value: '+57',
    flag: '🇨🇴',
    name: 'Colombia',
  },
]

type Props = {
  onClick?: (e: SyntheticEvent) => void | null | undefined
  id: string
  name: string
  variant: string
  label: string
  placeholder: string
  iconClick?: MouseEventHandler<HTMLSpanElement> | null | undefined
  value: string
  prefixList?: Country[]
  isCountryCode?: boolean
  onChange?: (e: any) => void | null | undefined
  onKeyPress?: (e: any) => void | null | undefined
  valueCountryCode?: any
  onChangeCode?: (e: any) => void
  maxLength?: number | undefined
  pattern?: string
  isCardNumber?: boolean
  fullWidth: boolean
  iconVariant?: string
  disabled?: boolean
  iconLeft?: string
  iconRight?: string
  error?: string
  helper?: string
  type?: string
  prefix?: string
  suffix?: string
  style?: any
  className?: string
  testId?: string
}

/**
 * Input UI component
 * Strict options for design described in: https://zpl.io/VkMdP5Z.
 * Inherits any props passed.
 * @param {*} param0 props
 * @param {String} param0.variant variant type (outline, inline)
 * @param {String} param0.error error message. If falsy does not show. If truthy string show message nad styles
 * @param {String} param0.id input id html property. required
 * @param {String} param0.label label to render. required
 * @param {String} param0.type input type. default text
 * @param {String} param0.helper helper text. if falsy does not show
 * @param {String} param0.placeholder input placeholder
 * @param {String} param0.iconLeft leading icon. material library filled name (eg: account_circle): https://fonts.google.com/icons?selected=Material+Icons
 * @param {String} param0.iconRight trailing icon. material library filled name (eg: info): https://fonts.google.com/icons?selected=Material+Icons
 * @param {Boolean} param0.disabled disabled state
 * @param {Function} param0.iconClick handler to execute if icon needs function
 * @param {String} param0.prefix prefix value. If falsy does not show
 * @param {String} param0.suffix prefix value. If falsy does not show
 * @param {*} param0.value input's value
 * @param {Function} param0.onChange change handler
 * @param {Array} param0.prefixList list of prefix select options. Default is country code type.
 * @param {Boolean} param0.isCountryCode flag for country code mode
 * @param {String} param0.valueCountryCode value for country code selection (selected option's value) in country code type
 * @param {Function} param0.onChangeCode change handler for country code in country code type
 * @param {String} param0.maxLength input's max length
 * @param {String} param0.pattern input's pattern
 * @param {Boolean} param0.isCardNumber flag for credit card input mode.
 * @param {Boolean} param0.fullWidth if present the input will have full width 100%
 * @param {String} param0.iconVariant material icons classname variant (regular/empty prop, outlined, sharp, round)
 * @returns {Void} JSX Component
 */
export const Input: FC<Props> = ({
  variant = 'outline',
  error = null,
  id,
  label,
  type = 'text',
  helper = null,
  placeholder = '',
  iconLeft,
  iconRight,
  disabled = false,
  iconClick,
  prefix,
  suffix,
  value = '',
  prefixList = countryList,
  isCountryCode = false,
  onChange,
  valueCountryCode = '',
  onChangeCode = () => {},
  pattern = '',
  maxLength,
  isCardNumber = false,
  fullWidth = null,
  iconVariant = 'regular',
  testId,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [countryCode, setCountryCode] = useState(valueCountryCode)
  const [cardIcon, setCardIcon] = useState('')

  const onFocus = useCallback(() => {
    setIsFocus(true)
  }, [])
  const onBlur = useCallback(() => {
    setIsFocus(false)
  }, [])

  const onChangeCountry = useCallback(
    (option: { value: any }) => {
      setCountryCode(option?.value || null)
      onChangeCode(option)
    },
    [onChangeCode],
  )

  useEffect(() => {
    const ccicon = document.getElementById('ccicon')
    if (ccicon) {
      ccicon.innerHTML = cardIcon
    }
  }, [cardIcon])

  const iconClass = InputThemes.icons[iconVariant] || InputThemes.icons.regular

  const componentContainerClasses = ['InputContainer']
  const classes = ['Input']
  const containerClasses = ['InnerContainer']
  const iconClasses = [iconClass, 'IconGroupAddon']
  const inlineClasses = ['Inline']

  const variantInput = InputThemes.variant[variant] || InputThemes.variant.default
  classes.push(variantInput)

  if (error) {
    containerClasses.push('Error')
    inlineClasses.push('ErrorInline')
  }

  if (disabled) {
    containerClasses.push('Disabled')
    inlineClasses.push('Disabled')
  }

  if (isFocus) {
    containerClasses.push('Focus')
    inlineClasses.push('Focus')
  }

  if (iconClick !== undefined) {
    iconClasses.push('IconClick')
  }

  if (value || placeholder) {
    inlineClasses.push('ActiveInput')
  }

  if (fullWidth) {
    componentContainerClasses.push('FullWidth')
    containerClasses.push('FullWidth')
    classes.push('FullWidth')
    inlineClasses.push('FullWidth')
  }

  const inputMaxLength = maxLength
  let inputPattern = pattern
  if (isCardNumber) {
    inputPattern = '[0-9]*'
  }

  return (
    <div className={componentContainerClasses.join(' ')}>
      {variant === 'outline' && (
        <>
          <label htmlFor={id} className={'Label'}>
            {label}
          </label>
          <div className={containerClasses.join(' ')}>
            {iconLeft && (
              <span
                className={['Prefix', ...iconClasses].join(' ')}
                onClick={iconClick || undefined}
              >
                {iconLeft}
              </span>
            )}
            {isCountryCode && (
              <div className={['InputGroupAddon', 'PrefixSelect'].join(' ')}>
                <SelectCountry
                  options={prefixList}
                  value={valueCountryCode}
                  onChange={onChangeCountry}
                />
              </div>
            )}
            {countryCode && (
              <span className={['InputGroupAddon', 'Prefix'].join(' ')}>{countryCode}</span>
            )}
            {cardIcon && (
              <div className={['InputGroupAddon', 'Prefix'].join(' ')}>
                <svg
                  id="ccicon"
                  className={'ccicon'}
                  width="750"
                  height="471"
                  viewBox="0 0 750 471"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                ></svg>
              </div>
            )}
            {prefix && <span className={['InputGroupAddon', 'Prefix'].join(' ')}>{prefix}</span>}
            <div className={classes.join(' ')}>
              <input
                id={id}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                value={value}
                onChange={isCardNumber ? undefined : onChange}
                maxLength={inputMaxLength}
                pattern={inputPattern}
                data-testid={testId}
                {...rest}
              />
            </div>
            {iconRight && (
              <span
                className={['Suffix', ...iconClasses].join(' ')}
                onClick={iconClick || undefined}
              >
                {iconRight}
              </span>
            )}
            {suffix && <span className={['InputGroupAddon', 'Suffix'].join(' ')}>{suffix}</span>}
          </div>
          {helper && <span className={'Helper'}>{helper}</span>}
          {error && (
            <div className={'ErrorContainer'}>
              <span className={['material-icons', 'ErrorIcon'].join(' ')}>error</span>
              <span className={'ErrorMessage'}>{error}</span>
            </div>
          )}
        </>
      )}
      {variant === 'inline' && (
        <>
          <div className={inlineClasses.join(' ')}>
            <label htmlFor={id} className={'LabelInline'}>
              {label}
            </label>
            {iconLeft && (
              <span
                className={['PrefixInline', ...iconClasses].join(' ')}
                onClick={iconClick || undefined}
              >
                {iconLeft}
              </span>
            )}
            {prefix && (
              <span className={['InputGroupAddon', 'PrefixInline'].join(' ')}>{prefix}</span>
            )}
            <input
              id={id}
              type={type}
              disabled={disabled}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              {...rest}
            />
            {iconRight && (
              <span
                className={['SuffixInline', ...iconClasses].join(' ')}
                onClick={iconClick || undefined}
              >
                {iconRight}
              </span>
            )}
            {suffix && (
              <span className={['InputGroupAddon', 'SuffixInline'].join(' ')}>{suffix}</span>
            )}
          </div>
          {helper && <span className={'Helper'}>{helper}</span>}
          {error && <span className={'ErrorMessage'}>{error}</span>}
        </>
      )}
    </div>
  )
}

export default Input
