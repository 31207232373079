import * as yup from 'yup'

import { getPhoneNumberLength } from '~utilities/locales.utilities'

const noSpace = (x: string) => {
  let quitSpace = ''

  if (x) {
    quitSpace = x.split(' ').join('')
    return quitSpace.split('-').join('')
  }

  return quitSpace
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPhoneSchema = (t: any, locale: any) => {
  return yup.object({
    phone: yup
      .string()
      .required(t('desktop.enterValidPhone'))
      .test(
        'phone-validation',
        t('desktop.enterValidEmail'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (value: any, { createError, path }) => {
          const newValue = noSpace(value)
          const { min, max } = getPhoneNumberLength(locale)

          if (!/^\d+$/.test(newValue)) {
            return createError({
              path,
              message: t('desktop.enterValidPhone'),
            })
          }

          if (min === max && (newValue.length < min || newValue.length > max)) {
            return createError({
              path,
              message: t('desktop.phoneMustDigits', { number: max }),
            })
          }

          if (min < max && newValue.length < min) {
            return createError({
              path,
              message: t('desktop.phoneMustLeastDigits', { number: min }),
            })
          }

          if (min < max && newValue.length > max) {
            return createError({
              path,
              message: t('desktop.phoneMustMostDigits', { number: max }),
            })
          }

          return true
        },
      ),
    confirmPhone: yup.boolean().oneOf([true], t('desktop.confirmPhoneCheckbox')),
  })
}

export type PhoneSchema = yup.InferType<ReturnType<typeof getPhoneSchema>>
