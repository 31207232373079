import styled from 'styled-components'

export const Content = styled.div`
  background: ${(props) => props.theme.colors.specialty.blue[50]};
  width: 100%;
  height: 752px;
  overflow: hidden;
  position: relative;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
`

export const ImageContainer = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 930px) {
    width: 60%;
    right: -3rem;
  }
  @media (max-width: 770px) {
    display: none;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  margin-top: 5.5rem;
  position: absolute;
  z-index: 2;
  @media (max-width: 770px) {
    width: 100%;
  }
`

export const Form = styled.div`
  width: 20.5rem;
`

export const Title = styled.h1`
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  color: ${(props) => props.theme.colors.grayscale[900]};
  text-align: center;
  white-space: pre-line;
  margin-bottom: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
`

export const Instruction = styled.p`
  color: ${(props) => props.theme.colors.grayscale[900]};
  line-height: 22px;
  text-align: center;
  padding: 0 1rem;
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
  strong {
    font-weight: bold;
  }
`

export const InputLable = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamilies.display};
  font-size: ${(props) => props.theme.typography.body2.fontSize};
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 22px;
`

export const InputContainer = styled.div`
  padding: 0.5rem 0;
  input {
    background-color: ${(props) => props.theme.colors.specialty.blue[50]};
  }
`

export const CheckboxContainer = styled.div`
  padding: 0.5rem 0;
  strong {
    font-family: ${(props) => props.theme.typography.fontFamilies.display};
    font-weight: bold;
  }
`

export const ButtonContainer = styled.div`
  padding: 2rem 0;
  text-align: center;
`
