import styled from 'styled-components'

export const Container = styled.footer`
  padding: 2.25rem 1rem;
  border-top: ${(props) => `1px solid ${props.theme.colors.grayscale[400]}`};
  width: 100%;
`

export const Divider = styled.div`
  margin: 2rem 0;
  border-top: ${(props) => `1px solid ${props.theme.colors.grayscale[400]}`};
`

export const SectionTitle = styled.h4`
  font-family: ${(props) => props.theme.typography.subtitle2.fontFamily};
  font-size: ${(props) => props.theme.typography.subtitle2.fontSize};
  font-weight: ${(props) => props.theme.typography.subtitle2.fontWeight};
  letter-spacing: ${(props) => props.theme.typography.subtitle2.letterSpacing};
  margin-bottom: 1rem;
`

export const Section = styled.section<{ spacing?: string }>`
  display: grid;
  gap: ${(props) => props.spacing || '0.5rem'};
`

export const SectionLink = styled.a`
  font-family: ${(props) => props.theme.typography.overline.fontFamily};
  font-size: ${(props) => props.theme.typography.overline.fontSize};
  font-weight: ${(props) => props.theme.typography.overline.fontWeight};
  letter-spacing: ${(props) => props.theme.typography.overline.letterSpacing};
  line-height: ${(props) => props.theme.typography.overline.lineHeight};
  color: ${(props) => props.theme.colors.grayscale[600]};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const SectionText = styled.p<{ align?: string }>`
  font-family: ${(props) => props.theme.typography.overline.fontFamily};
  font-size: ${(props) => props.theme.typography.overline.fontSize};
  font-weight: ${(props) => props.theme.typography.overline.fontWeight};
  letter-spacing: ${(props) => props.theme.typography.overline.letterSpacing};
  line-height: ${(props) => props.theme.typography.overline.lineHeight};
  color: ${(props) => props.theme.colors.grayscale[600]};
  text-align: ${(props) => props.align || 'inherit'};
`
