import styled from 'styled-components'

import { SectionText } from '~components/Layout/components/Footer/Footer.styled'

export const Footer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: 48px;
  border-color: #bdbdbd;
  border-style: solid;
  border-width: 1px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`

export const SectionTextDesktop = styled(SectionText)``
