import '@mymoons/ui-library/dist/library.min.css'
import 'react-responsive-modal/styles.css'
import { PageLoader } from '@mymoons/ui-library'
import { NextIntlProvider } from 'next-intl'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'

import { ErrorBoundary } from '~components/ErrorBoundary/ErrorBoundary'
import { GlobalStyle } from '~components/GlobalStyle'
import { GoogleTagManagerScript } from '~components/GoogleTagManager'
import { Layout } from '~components/Layout'
import { Show } from '~components/Show'
import { AppContextProvider } from '~context/AppContext'
import { DesktopPage } from '~modules/desktop/pages/Desktop.page'
import { moonsTheme } from '~themes/themes'

import { defaultSeo } from '../../next-seo.config'

const queryClient = new QueryClient()
const gtmId = process.env.NEXT_PUBLIC_GTM_ID

function MyApp({ Component, pageProps, router }: AppProps) {
  const [isPageLoading, setIsPageLoading] = useState(false)

  useEffect(() => {
    const handleStart = () => {
      setIsPageLoading(true)
    }

    const handleStop = () => {
      setIsPageLoading(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  const language: string = router.locale || 'es-MX'

  return (
    <ErrorBoundary>
      {gtmId && <GoogleTagManagerScript id={gtmId} />}
      <DefaultSeo {...defaultSeo} />
      <NextIntlProvider locale={language} messages={pageProps.messages}>
        <ThemeProvider theme={moonsTheme}>
          <GlobalStyle />
          <QueryClientProvider client={queryClient}>
            <AppContextProvider>
              <Layout>
                {isPageLoading && <PageLoader />}
                <Show on="mobile">
                  <Component {...pageProps} />
                </Show>
                <Show on="desktop">
                  <DesktopPage />
                </Show>
              </Layout>
            </AppContextProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ThemeProvider>
      </NextIntlProvider>
    </ErrorBoundary>
  )
}

export default MyApp
