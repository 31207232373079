import type { DefaultSeoProps } from 'next-seo'

import { ASSETS_S3 } from '~constants/assetsS3.constants'

export const defaultSeo: DefaultSeoProps = {
  titleTemplate: '%s | moons',
  title: 'Pre-valoración dental en línea gratis',
  description: 'Hazte la pre-valoración y descubre el estado de tu salud dental',
  openGraph: {
    title: 'Pre-valoración dental en línea gratis',
    description: 'Hazte la pre-valoración y descubre el estado de tu salud dental',
    images: [
      {
        url: ASSETS_S3.concat('/images/opengraph.png'),
      },
    ],
  },
  // Only indexes production website
  dangerouslySetAllPagesToNoFollow: process.env.NODE_ENV !== 'production',
  dangerouslySetAllPagesToNoIndex: process.env.NODE_ENV !== 'production',
}
